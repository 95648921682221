.about-bg {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: calc(100vh - 100px);
}

.about-content {
    color: #000000;
    background-color: rgba(255, 255, 255, 0.8);
    height: 100%;
    width: 100%;
    padding: 20px;
    min-height: calc(100vh - 100px);
}