/* .sideColumn {
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
} */

.avatarFrame {
    height: 180px;
    padding: 15px 0;
}

.avatar {
    height: 150px;
    width: 150px;
}

.bloggerName {
    font-weight: 700;
    font-size: larger;
    color: gray;
    margin: 5px 0;
}

.bloggerHeadline {
    font-size: small;
    color: gray;
    margin: 5px 0;
}

.bloggerLinks {
    margin-top: 0px;
    padding: 10px 0 0 0;
}

.bloggerLink {
    margin: 5px 0;
    font-size: 0.9em;
}

.bloggerLink a {
    color: #666666;
    text-decoration: none;
}

@media screen and (max-width: 991px) {
    .sideColumn {
        display: flex;
        display: -webkit-flex;
        align-items: center;
    }
    .avatar {
        height: 70px;
        width: 70px;
    }
    .avatarFrame {
        height: 100px;
    }
    .bloggerInfo {
        flex: 1;
        padding-left: 20px
    }
    .bloggerLinks {
        padding-top: 0px;
        padding-left: 10px;
        padding-right: 20px;
    }
}

@media screen and (max-width: 575px) {
    .bloggerInfo {
        padding-left: 10px;
    }
    .bloggerName {
        font-size: medium;
    }
    .bloggerHeadline {
        font-size: x-small;
    }
    .bloggerLink {
        font-size: 0.7em;
    }
    .avatar {
        height: 45px;
        width: 45px;
    }
    .avatarFrame {
        height: 75px;
    }
}